import { $t } from '@/i18n';
import useValidate from '@/use/validate';
import { submitPersonal, submitFinalStep } from '@/handlers/shared';

const {
  isEmail,
  isPassword,
  isWeakPhone,
} = useValidate();

export default () => ({
  id: 'free',

  fallbackFlowId: null,

  layout: {
    path: 'layouts/DefaultLayout',
    props: {},
  },

  steps: [
    {
      id: 'home',
      routeName: null,
      component: {
        path: 'views/shared/HomeView',
      },
      isPublic: true,
      headerTheme: 'blue',
      saveSessionOnSubmit: false,
      analyticsMetaFields: {
        eloquaFormName: $t('flows.free.steps.home.eloquaFormName'),
      },
      fields: [
        {
          name: 'email',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.businessEmail'),
          validatorClientSide: ({ email }) => isEmail(email.value),
        },
      ],
    },

    {
      id: 'confirm',
      routeName: 'confirm',
      component: {
        path: 'views/shared/ConfirmView',
      },
      isPublic: true,
      saveSessionOnSubmit: true,
      fields: [
        {
          name: 'code',
          value: [],
          defaultValue: [],
          isOptional: true,
          omitFromSession: true,
        },
      ],
    },

    {
      id: 'profile',
      routeName: 'profile',
      component: {
        path: 'views/shared/ProfileView',
      },
      isPublic: false,
      stepperLabel: $t('flows.shared.steps.profile.stepperLabel'),
      saveSessionOnSubmit: true,
      fields: [
        {
          name: 'firstName',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.firstName'),
        },
        {
          name: 'lastName',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.lastName'),
        },
        {
          name: 'phone',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.phone'),
          validatorClientSide: ({ phone }) => isWeakPhone(phone.value),
        },
        {
          name: 'company',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.company'),
        },
        {
          name: 'password',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.password'),
          omitFromSession: true,
          validatorClientSide: ({ password }) => isPassword(password.value),
        },
        {
          name: 'country',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.country'),
          isTrackedByAnalytics: true,
        },
        {
          name: 'consent',
          value: null,
          defaultValue: null,
          isOptional: true,
        },
        {
          name: 'agreeToTerms',
          value: false,
          defaultValue: false,
          validatorClientSide: ({ agreeToTerms }) => agreeToTerms.value === true,
        },
      ],
    },

    {
      id: 'personal',
      routeName: 'personal',
      component: {
        path: 'views/shared/PersonalView',
      },
      isPublic: false,
      stepperLabel: $t('flows.shared.steps.personal.stepperLabel'),
      saveSessionOnSubmit: true,
      submitHandler: submitPersonal,
      fields: [
        {
          name: 'department',
          value: '',
          defaultValue: '',
          isTrackedByAnalytics: true,
          isOptionalIf: ({ departmentOther }) => !!departmentOther.value,
        },
        {
          name: 'departmentOther',
          value: '',
          defaultValue: '',
          isTrackedByAnalytics: true,
          isOptionalIf: ({ department }) => !!department.value,
        },
        {
          name: 'role',
          value: '',
          defaultValue: '',
          isTrackedByAnalytics: true,
          isOptionalIf: ({ roleOther }) => !!roleOther.value,
        },
        {
          name: 'roleOther',
          value: '',
          defaultValue: '',
          isTrackedByAnalytics: true,
          isOptionalIf: ({ role }) => !!role.value,
        },
        {
          name: 'jobTitle',
          value: '',
          defaultValue: '',
          isTrackedByAnalytics: true,
        },
      ],
    },

    {
      id: 'walkthrough',
      routeName: 'walkthrough',
      component: {
        path: 'views/shared/WalkthroughView',
      },
      isPublic: false,
      stepperLabel: $t('flows.shared.steps.walkthrough.stepperLabel'),
      saveSessionOnSubmit: true,
      submitHandler: submitFinalStep,
      fields: [
        {
          name: 'poc',
          value: null,
          defaultValue: null,
          isTrackedByAnalytics: true,
        },
        {
          name: 'isWalkthroughScheduled',
          value: false,
          defaultValue: false,
          isOptional: true,
          isTrackedByAnalytics: true,
        },
      ],
    },
  ],
});
